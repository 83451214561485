import client from './index'

import {
  ApiPaginationResponse,
  PaginationParams,
  ApiResponse,
} from '@/models/api'
import {
  User,
  UserStatus,
  UpdateableUserParams,
  UserWithCarsProfiles,
  UserInsurancePolicy,
  UpdatableUserInsurancePolicyParams,
} from '@/models/user'
import { PapersVerifyingPayload } from '@/store/users'

export const getUsers = (params: PaginationParams) => {
  return client.get<ApiPaginationResponse<User>>('/users', { params })
}

export const getAllUsers = () => {
  return client.get<User[]>('/users/all')
}

export const checkStatusUsers = () => {
  return client.get<User[]>('/users/check-status')
}

export const pushOneNotification = (notificationData: any) => {
  return client.post<ApiResponse<void>>(
    '/push-notifications/user',
    notificationData
  )
}

export const pushManyNotification = (notificationData: any) => {
  return client.post<ApiResponse<void>>(
    '/push-notifications/users',
    notificationData
  )
}

export const getUserDetails = (id: string) => {
  return client.get<ApiResponse<UserWithCarsProfiles>>(`/users/${id}`)
}

export const updateUserStatus = (id: string, status: UserStatus) => {
  return client.put<ApiResponse<{ status: UserStatus }>>(
    `/users/${id}/update-status`,
    { status }
  )
}

export const verifyPaper = (payload: PapersVerifyingPayload) => {
  return client.put(`/users/${payload.id}/verify-papers`, {
    name: payload.paper,
    verified: payload.value,
  })
}

export const resetPassword = (userId: string) =>
  client.get<ApiResponse<void>>(`/users/${userId}/reset-password`)

export const updateUser = (id: string, params: UpdateableUserParams) => {
  return client.put<ApiResponse<User>>(`/users/${id}`, params)
}

export const getUserInsuranceDetails = (id: string) => {
  return client.get<ApiResponse<UserInsurancePolicy>>(
    `/users/${id}/insurance-information`
  )
}
// export const getUserInsuranceDetails = async (id: string) => {
//   const response = await client.get<ApiResponse<UserInsurancePolicy>>(
//     `/users/${id}/insurance-information`
//   )
//   console.log(response);
//   return response;
// }

export const updateUserInsuranceDetails = (
  id: string,
  params: Partial<UpdatableUserInsurancePolicyParams>
) => {
  return client.put<ApiResponse<UserInsurancePolicy>>(
    `/users/${id}/insurance-information`,
    params
  )
}

export const deleteUserPaymentCard = ({
  userId,
  cardId,
}: {
  userId: string
  cardId: string
}) => {
  // console.log("🚀 ~ cardId:", cardId)
  // console.log("🚀 ~ userId:", userId)
  return client.delete<ApiResponse<void>>(
    `/users/${userId}/paymentsCard/${cardId}`
  )
}

export const createUserInsuranceDetails = (
  id: string,
  params: UpdatableUserInsurancePolicyParams
) => {
  return client.post<ApiResponse<UserInsurancePolicy>>(
    `/users/${id}/insurance-information`,
    params
  )
}
