import {
    CommonModule,
    ModuleWithData,
    ModuleError,
    PaginationModule,
  } from '@/models/store';
  
  import {
    EvCarProfile,
    EvCarProfileStatus,
    EvCarProfileUpdateableKeys,
  } from '@/models/evCarProfile';
  
  import {
    getInitialCommonModuleState,
    getInitialDataModuleState,
    getPagintaionModuleState,
  } from '@/helpers/store-state';
  
  export type GetEvCarsParams = {
    status?: EvCarProfileStatus;
    admin_id?: string;
  };
  
  export interface EvCarUpdatingModule {
    loadingKeys: EvCarProfileUpdateableKeys[];
    successKeys: EvCarProfileUpdateableKeys[];
    error: null | ModuleError<EvCarProfileUpdateableKeys>;
  }
  
  export interface EvCarDetailsModule extends ModuleWithData<EvCarProfile> {
    statusUpdating: CommonModule;
    updating: EvCarUpdatingModule;
  }
  
  export interface EvCarsState {
    listModule: PaginationModule<EvCarProfile>;
  
    // EvCar details grouped by vehicle id
    evCarsDetails: Record<string, EvCarDetailsModule | undefined>;
  }
  
  export const getInitialEvCarDetailsState = (): EvCarDetailsModule => ({
    ...getInitialDataModuleState(),
    statusUpdating: getInitialCommonModuleState(),
    updating: { error: null, loadingKeys: [], successKeys: [] },
  });
  
  export default (): EvCarsState => ({
    listModule: getPagintaionModuleState(),
    evCarsDetails: {},
  });
  