export enum RbacAction {
  READ = 'read',
  UPDATE = 'update',
  CREATE = 'create',
}

export enum RbacEntity {
  USERS = 'users',
  CARS = 'carProfiles',
  CARS_EV = 'evCarProfiles',
  APP_SETTINGS = 'appSettings',
  TRIPS = 'trips',
  ADMIN_PROFILES = 'adminProfiles',
  LOGS = 'logs',
  WALLET = 'wallet',
  USER_HOST_SECTION = 'userHostSection',
  MANUAL_FINANCIAL_OPERATIONS = 'manualFinancialOperations',
  INSURANCE_VEHICLE = 'insuranceVehicle',
  INSURANCE_USER = 'insuranceUser',
}

export const getScope = (action: RbacAction, entity: RbacEntity): string => {
  return `${action}_${entity}`
}

export const getReadScopes = (entities: RbacEntity[]): string[] => {
  return entities.map(e => getScope(RbacAction.READ, e))
}
