import { defineStore } from 'pinia';
import { ElNotification } from 'element-plus';

import { PaginationParams } from '@/models/api';
import {
  EvCarProfile,
  EvCarProfileStatus,
  EvCarProfileUpdateableKeys,
  EvCarProfileUpdateParams,
  EvCarImage
} from '@/models/evCarProfile';

import evCarsApi from '@/api/car-ev-data';

import { parseErrors } from '@/helpers/error-parsers';
import { commonCase } from '@/helpers/strings'
import { getInitialDataModuleState } from '@/helpers/store-state';

import getInitialState, {
  getInitialEvCarDetailsState,
  EvCarsState,
  GetEvCarsParams,
} from './state';

function mapApiResponseToEvCarProfile(data: any): EvCarProfile {
  return {
    id: data.id ?? '',
    admin_id: data.admin_id ?? '',
    place_id: data.place_id ?? null,
    address: data.address ?? null,
    model_make_id: data.model_make_id ?? '',
    model_id: data.model_id ?? null,
    model_name: data.model_name ?? '',
    model_year: data.model_year ?? 0,
    battery_capacity: data.battery_capacity ?? 0,
    charging_connector_type: data.charging_connector_type ?? '',
    charging_time: data.charging_time ?? 0,
    range_per_charge: data.range_per_charge ?? 0,
    price_per_minute_ev: data.price_per_minute_ev ?? 0,
    price_per_km_ev: data.price_per_km_ev ?? 0,
    price_per_minute_up_to_100_km_ev: data.price_per_minute_up_to_100_km_ev ?? 0,
    price_per_minute_above_100_km_ev: data.price_per_minute_above_100_km_ev ?? 0,
    pause_price_ev: data.pause_price_ev ?? 0,
    deposit_amount_ev: data.deposit_amount_ev ?? 0,
    car_type: data.car_type ?? '',
    transmission: data.transmission ?? '',
    fuel_type: data.fuel_type ?? '',
    no_branded_title: data.no_branded_title ?? false,
    identity_country: data.identity_country ?? '',
    plate_number: data.plate_number ?? '',
    seats: data.seats ?? null,
    doors: data.doors ?? null,
    // status: data.status ?? 'inactive',
    status: (data.status as EvCarProfileStatus) ?? EvCarProfileStatus.INCOMPLETE,
    car_description: data.car_description ?? null,
    charging_included: data.charging_included ?? false,
    battery_included: data.battery_included ?? false,
    charging_policy: data.charging_policy ?? null,
    features: data.features ?? {
      gps: data.gps ?? null,
      air_conditioner: data.air_conditioner ?? null,
      bluetooth_stereo: data.bluetooth_stereo ?? null,
      wifi_internet: data.wifi_internet ?? null,
      fire_extinguisher: data. fire_extinguisher ?? null,
    },
    images: data.images ?? [],
    createdAt: data.createdAt ?? '',
    updatedAt: data.updatedAt ?? '',
  };
}





export const useEvCarsStore = defineStore({
  id: 'evCars-store',
  state: getInitialState,
  actions: {

    async deleteEvCarImage(carId: string, imageId: string) {
      try {
        await evCarsApi.deleteEvCarImage(carId, imageId);

        this.$patch((state: EvCarsState) => {
          const carDetails = state.evCarsDetails[carId];
          if (carDetails?.data?.images) {
            carDetails.data.images = carDetails.data.images.filter(
              (image) => image.id !== imageId
            );
          }
        });

        ElNotification({
          type: 'success',
          message: 'Image successfully deleted.',
          duration: 6000,
        });
      } catch (error) {
        const { error: parsedError } = parseErrors(error);

        ElNotification({
          type: 'error',
          message: parsedError || 'Failed to delete image.',
          duration: 10000,
        });

        throw error; 
      }
    },
 
    async uploadImg(carId: string, file: File, type: string) {
      try {
        if (!carId) {
          throw new Error('Car ID is not defined.');
        }
    
        console.log(`Uploading image for type: ${type} Car ID: ${carId}`);
    
        const response = await evCarsApi.uploadEvCarImage(carId, file, type);
    
        const uploadedImage = response.data.data as EvCarImage;
    
        if (!uploadedImage) {
          throw new Error(`Uploaded image data for type "${type}" not found in response.`);
        }
    
        this.$patch((state: EvCarsState) => {
          const carDetails = state.evCarsDetails[carId];
          if (carDetails?.data?.images) {
            carDetails.data.images.push(uploadedImage);
          } else if (carDetails?.data) {
            carDetails.data.images = [uploadedImage];
          }
        });
    
        ElNotification({
          type: 'success',
          message: `${type} image uploaded successfully.`,
          duration: 6000,
        });
      } catch (error) {
        const parsedError = parseErrors(error);
    
        const errorMessage = typeof parsedError === 'string' ? parsedError : 'Failed to upload image.';

        ElNotification({
          type: 'error',
          message: errorMessage,
          duration: 10000,
        });
    
        throw error;
      }
    },
    
    async getEvCars(params: PaginationParams & GetEvCarsParams) {
      
      try {
        this.$patch({ listModule: { isLoading: true, error: null } });
    
        const response = await evCarsApi.getEvCarProfiles(params);
        const list: EvCarProfile[] = response.data.data.map(mapApiResponseToEvCarProfile);
    
        const meta = response.data.meta ?? {
          total: 0,
          perPage: params.perPage || 10,
          currentPage: params.page || 1,
          lastPage: 1,
        };
    
        this.$patch({
          listModule: {
            list,
            meta,
            isLoading: false,
            isInitialLoadingDone: true,
          },
        });
      } catch (e) {
        this.$patch({
          listModule: {
            error: parseErrors(e).error,
            isLoading: false,
            isInitialLoadingDone: true,
          },
        });
      }
    },
    
    async getEvCarDetails(id: string) {
      try {
        const evCarsDetails = this.evCarsDetails;
    
        if (!evCarsDetails[id]) {
          evCarsDetails[id] = getInitialEvCarDetailsState();
        }
    
        this.$patch({
          evCarsDetails: { [id]: { isLoading: true, error: null } },
        });
    
        const response = await evCarsApi.getEvCarProfileById(id); 
        const data = response.data.data;
    
        this.$patch({
          evCarsDetails: {
            [id]: {
              isLoading: false,
              data: mapApiResponseToEvCarProfile(data), 
            },
          },
        });
      } catch (e) {
        const { error } = parseErrors(e);
        this.$patch({
          evCarsDetails: { [id]: { isLoading: false, error } },
        });
      }
    },
    
    async updateEvCarStatus(id: string, status: EvCarProfileStatus) {
      try {
        if (!this.evCarsDetails[id]?.data) {
          ElNotification({
            type: 'error',
            message: 'EV Car data is not available for update.',
            duration: 6000,
          });
          return;
        }

        this.$patch({
          evCarsDetails: {
            [id]: { statusUpdating: { isLoading: true, error: null } },
          },
        });

        await evCarsApi.updateEvCarProfile(id, { status });

        this.$patch((state: EvCarsState) => {
          const evCarModule = state.evCarsDetails[id];

          if (evCarModule?.data) {
            evCarModule.data.status = status;
            evCarModule.statusUpdating.isLoading = false;
          }

          const evCarInList = state.listModule.list.find(
            (item) => item.id === id
          );
          if (evCarInList) evCarInList.status = status;
        });

        ElNotification({
          type: 'success',
          duration: 6000,
          message: 'EV Car status was successfully updated.',
        });
      } catch (e) {
        const { error } = parseErrors(e);

        this.$patch({
          evCarsDetails: {
            [id]: { statusUpdating: { isLoading: false, error } },
          },
        });

        ElNotification({ message: error, duration: 10000, type: 'error' });
      }
    },




    // async updateEvVehicle(id: string, params: EvCarProfileUpdateParams) {
    //   console.log("🚀 ~ updateEvVehicle ~ params:", params)
    //   const updatedKeys = Object.keys(params) as EvCarProfileUpdateableKeys[];
    
    //   const keysString = updatedKeys.map((key) => commonCase(key, true)).join(', ');
    
    //   try {
    //     if (!this.evCarsDetails[id]?.data) {
    //       throw new Error('EV Car data must be received before updating');
    //     }
    
    //     this.$patch((state: EvCarsState) => {
    //       const updatingModule = state.evCarsDetails[id]?.updating;
    
    //       if (!updatingModule) return;
    
    //       updatingModule.error = null;
    //       updatedKeys.forEach((key) => {
    //         if (!updatingModule.loadingKeys.includes(key)) {
    //           updatingModule.loadingKeys.push(key);
    //         }
    
    //         const successIndex = updatingModule.successKeys.indexOf(key);
    //         if (successIndex !== -1) {
    //           updatingModule.successKeys.splice(successIndex, 1);
    //         }
    //       });
    //     });
    
    //     const { data: updatedVehicle } = await evCarsApi.updateEvCarProfile(id, params);
    
    //     if (updatedVehicle.status) {
    //       updatedVehicle.status = updatedVehicle.status as EvCarProfileStatus;
    //     }
    
    //     this.$patch((state: EvCarsState) => {
    //       const detailsModule = state.evCarsDetails[id];
    
    //       if (!detailsModule?.data) return;
    
    //       detailsModule.data = { ...detailsModule.data, ...updatedVehicle };
    
    //       const updatingModule = detailsModule.updating;
    //       updatedKeys.forEach((key) => {
    //         if (!updatingModule.successKeys.includes(key)) {
    //           updatingModule.successKeys.push(key);
    //         }
    //       });
    //     });
    
    //     ElNotification({
    //       type: 'success',
    //       duration: 6000,
    //       message: `${keysString} value(s) successfully updated.`,
    //     });
    //   } catch (e) {
    //     const { error, validationErrors, validationErrorsRaw } = parseErrors(e);
    
    //     this.$patch({
    //       evCarsDetails: {
    //         [id]: {
    //           updating: { error: validationErrors || error },
    //         },
    //       },
    //     });
    
    //     const message = `An error occurred while updating ${keysString}: "${
    //       validationErrorsRaw?.[0]?.message || error
    //     }"`;
    
    //     ElNotification({ message, type: 'error', duration: 10000 });
    //   } finally {
    //     this.$patch((state: EvCarsState) => {
    //       const updatingModule = state.evCarsDetails[id]?.updating;
    
    //       if (!updatingModule) return;
    
    //       updatedKeys.forEach((key) => {
    //         const loadingIndex = updatingModule.loadingKeys.indexOf(key);
    //         if (loadingIndex !== -1) {
    //           updatingModule.loadingKeys.splice(loadingIndex, 1);
    //         }
    //       });
    //     });
    //   }
    // }



    
    

    
    async updateEvVehicle(id: string, params: EvCarProfileUpdateParams | FormData) {
      console.log("updateEvVehicle called with ID:", id);
      console.log("Params received:", params);
    
      const updatedKeys = params instanceof FormData
        ? Array.from(params.keys()) as EvCarProfileUpdateableKeys[]
        : Object.keys(params) as EvCarProfileUpdateableKeys[];
    
      console.log("Updated keys derived:", updatedKeys);
    
      const keysString = updatedKeys.map((key) => commonCase(key, true)).join(', ');
      console.log("Keys string for notification:", keysString);
    
      try {
        if (!this.evCarsDetails[id]?.data) {
          console.error("EV Car data is missing for ID:", id);
          throw new Error('EV Car data must be received before updating');
        }
    
        console.log("Patching state before update...");
        this.$patch((state: EvCarsState) => {
          const updatingModule = state.evCarsDetails[id]?.updating;
          if (!updatingModule) {
            console.warn("Updating module not found for ID:", id);
            return;
          }
    
          updatingModule.error = null;
          updatedKeys.forEach((key) => {
            if (!updatingModule.loadingKeys.includes(key)) {
              updatingModule.loadingKeys.push(key);
            }
            const successIndex = updatingModule.successKeys.indexOf(key);
            if (successIndex !== -1) {
              updatingModule.successKeys.splice(successIndex, 1);
            }
          });
        });
    
        console.log("Sending API request to update EV vehicle...");
        const { data: updatedVehicle } = await evCarsApi.updateEvCarProfile(id, params);
    
        console.log("API response received:", updatedVehicle);
        console.log("🚀 API response received:", updatedVehicle);

    
        if (!updatedVehicle) {
          console.error("No updated vehicle data received from API!");
          throw new Error("Failed to fetch updated vehicle data");
        }
    
        if (updatedVehicle.status && typeof updatedVehicle.status !== 'number') {
          updatedVehicle.status = updatedVehicle.status as EvCarProfileStatus;
        } else {
          console.warn("Unexpected status format in API response:", updatedVehicle.status);
        }
    
        console.log("Patching state with updated vehicle data...");
        this.$patch((state: EvCarsState) => {
          const detailsModule = state.evCarsDetails[id];
          if (!detailsModule?.data) {
            console.warn("Details module not found for ID:", id);
            return;
          }
          if (detailsModule?.data) {
            Object.assign(detailsModule.data, updatedVehicle);
          }
    
          detailsModule.data = {
            ...detailsModule.data,
            ...updatedVehicle, 
          };
    
          const updatingModule = detailsModule.updating;
          updatedKeys.forEach((key) => {
            if (!updatingModule.successKeys.includes(key)) {
              updatingModule.successKeys.push(key);
            }
          });
        });
    
        console.log("Showing success notification...");
        ElNotification({
          type: 'success',
          duration: 6000,
          message: `${keysString} value(s) successfully updated.`,
        });
      } catch (e) {
        console.error("Error occurred during update:", e);
    
        const { error, validationErrors, validationErrorsRaw } = parseErrors(e);
        console.log("Parsed errors:", { error, validationErrors, validationErrorsRaw });
    
        this.$patch({
          evCarsDetails: {
            [id]: {
              updating: { error: validationErrors || error },
            },
          },
        });
    
        const message = `An error occurred while updating ${keysString}: "${
          validationErrorsRaw?.[0]?.message || error
        }"`;
    
        console.log("Showing error notification...");
        ElNotification({ message, type: 'error', duration: 10000 });
      } finally {
        console.log("Cleaning up loading keys...");
        this.$patch((state: EvCarsState) => {
          const updatingModule = state.evCarsDetails[id]?.updating;
          if (!updatingModule) {
            console.warn("Updating module not found during cleanup for ID:", id);
            return;
          }
    
          updatedKeys.forEach((key) => {
            const loadingIndex = updatingModule.loadingKeys.indexOf(key);
            if (loadingIndex !== -1) {
              updatingModule.loadingKeys.splice(loadingIndex, 1);
            }
          });
        });
        console.log("Finished cleanup.");
      }
    }
    
    
    // async updateEvVehicle(id: string, params: EvCarProfileUpdateParams | FormData) {
    //   const updatedKeys = params instanceof FormData 
    //     ? Array.from(params.keys()) as EvCarProfileUpdateableKeys[] 
    //     : (Object.keys(params) as EvCarProfileUpdateableKeys[]);
    
    //   const keysString = updatedKeys.map((key) => commonCase(key, true)).join(', ');
    
    //   try {
    //     if (!this.evCarsDetails[id]?.data) {
    //       throw new Error('EV Car data must be received before updating');
    //     }
    
    //     this.$patch((state: EvCarsState) => {
    //       const updatingModule = state.evCarsDetails[id]?.updating;
    //       if (!updatingModule) return;
    
    //       updatingModule.error = null;
    //       updatedKeys.forEach((key) => {
    //         if (!updatingModule.loadingKeys.includes(key)) {
    //           updatingModule.loadingKeys.push(key);
    //         }
    //         const successIndex = updatingModule.successKeys.indexOf(key);
    //         if (successIndex !== -1) {
    //           updatingModule.successKeys.splice(successIndex, 1);
    //         }
    //       });
    //     });
    
    //     const { data: updatedVehicle } = await evCarsApi.updateEvCarProfile(id, params);
    
    //     if (updatedVehicle.status) {
    //       updatedVehicle.status = updatedVehicle.status as EvCarProfileStatus;
    //     }
    
    //     this.$patch((state: EvCarsState) => {
    //       const detailsModule = state.evCarsDetails[id];
    //       if (!detailsModule?.data) return;
    
    //       detailsModule.data = { ...detailsModule.data, ...updatedVehicle };
    
    //       const updatingModule = detailsModule.updating;
    //       updatedKeys.forEach((key) => {
    //         if (!updatingModule.successKeys.includes(key)) {
    //           updatingModule.successKeys.push(key);
    //         }
    //       });
    //     });
    
    //     ElNotification({
    //       type: 'success',
    //       duration: 6000,
    //       message: `${keysString} value(s) successfully updated.`,
    //     });
    //   } catch (e) {
    //     const { error, validationErrors, validationErrorsRaw } = parseErrors(e);
    
    //     this.$patch({
    //       evCarsDetails: {
    //         [id]: {
    //           updating: { error: validationErrors || error },
    //         },
    //       },
    //     });
    
    //     const message = `An error occurred while updating ${keysString}: "${
    //       validationErrorsRaw?.[0]?.message || error
    //     }"`;
    
    //     ElNotification({ message, type: 'error', duration: 10000 });
    //   } finally {
    //     this.$patch((state: EvCarsState) => {
    //       const updatingModule = state.evCarsDetails[id]?.updating;
    //       if (!updatingModule) return;
    
    //       updatedKeys.forEach((key) => {
            
    //         const loadingIndex = updatingModule.loadingKeys.indexOf(key);
    //         if (loadingIndex !== -1) {
    //           updatingModule.loadingKeys.splice(loadingIndex, 1);
    //         }
    //       });
    //     });
    //   }
    // },

    
  },
});
