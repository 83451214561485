// import client from './index'

//     export interface EvCarProfile {
//         place_id?: string;
//         model_id?: number;
//         model_make_id: string;
//         model_name: string;
//         model_year: number;
//         model_trim?: string;
//         battery_capacity: number;
//         charging_connector_type: string;
//             // | 'Type 1 (J1772)'
//             // | 'Type 2 (Mennekes)'
//             // | 'CHAdeMO'
//             // | 'CCS (Combined Charging System)'
//             // | 'Tesla Supercharger';
//         charging_time: number;
//         range_per_charge: number;
//         price_per_minute_ev: number;
//         price_per_km_ev: number;
//         price_per_minute_up_to_100_km_ev: number;
//         price_per_minute_above_100_km_ev: number;
//         pause_price_ev: number;
//         deposit_amount_ev: number;
//         car_type: string;
//         address: string;
//         transmission: 'automatic';
//         fuel_type: 'electric';
//         no_branded_title: boolean;
//         identity_country: string;
//         plate_number: string;
//         car_description: string;
//         charging_included?: boolean;
//         battery_included?: boolean;
//         charging_policy?: string;
//         status?: string;
//         user_id: string;
//         gps?: boolean;
//         air_conditioner?: boolean;
//         seats?: number;
//         doors?: number;
//         bluetooth_stereo?: boolean;
//         wifi_internet?: boolean;
//         fire_extinguisher?: boolean;
//         ownership_permit?: string[];
//     }
    

//     export interface ApiResponse<T> {
//         message: string;
//         status: string;
//         code: number;
//         data: T;
//         meta?: {
//             total: number;
//             perPage: number;
//             currentPage: number;
//             lastPage: number;
//             firstPage: string;
//             firstPageUrl: string;
//             lastPageUrl: string;
//             nextPageUrl: string;
//             previousPageUrl: string;
//         };
//         id?: string;
//     }

//     export interface ApiError {
//         message: string;
//         status: string;
//         code: number;
//         errors?: Record<string, unknown>;
//     }

//     export interface UploadedImage {
//         file: File;
//         preview: string;
//     }

//     export const createEvCarProfile = async (data: Omit<EvCarProfile, 'id'>) => {
//         return client.post<ApiResponse<{ id: string }>>('/cars/ev', data);
//     };

//     export const getEvCarProfiles = async (params: Record<string, any>) => {

//         return client.get<ApiResponse<EvCarProfile[]>>('/cars/ev', { params });
//     };

//     export const getEvCarProfileById = async (id: string) => {
//         return client.get<ApiResponse<EvCarProfile>>(`/cars/ev/${id}`);
//     };

//     export const updateEvCarProfile = async (
//         id: string,
//         data: Record<string, any> | FormData
//     ) => {
//         let requestData: FormData | Record<string, any>;
    
//         if (!(data instanceof FormData)) {
//             requestData = new FormData();
//             Object.entries(data).forEach(([key, value]) => {
//                 requestData.append(key, value as string | Blob);
//             });
//         } else {
//             requestData = data;
//         }

//         console.log('🚀 Final requestData:', Array.from((requestData as FormData).entries()));

    
//         return client.put<ApiResponse<EvCarProfile>>(`/cars/ev/${id}`, requestData, {
//             headers: {
//                 'Content-Type': 'multipart/form-data',
//             },
//         });
//     };
    
    

//     export const deleteEvCarProfile = async (id: string) => {
//         return client.delete<ApiResponse<null>>(`/cars/ev/${id}`);
//     };
//     export const deleteEvCarImage = (carId: string, imageId: string) => {
//         return client.delete<ApiResponse<null>>(`/cars/ev/${carId}/images/${imageId}`);
//     };

    
//     export const uploadEvCarImage = async (carId: string, file: File, type: string) => {
//         const formData = new FormData();
//         formData.append('image', file);
//         formData.append('type', type);
//         return client.post<ApiResponse<{ secure_url: string; public_id: string }>>(
//             `/cars/ev/${carId}/images`,
//             formData,
//             {
//                 headers: {
//                     'Content-Type': 'multipart/form-data',
//                 },
//             }
//         );
//     };
    
//     export const getEvCarImages = async (carId: string) => {
//         return client.get<ApiResponse<{ secure_url: string; public_id: string }[]>>(`/cars/ev/${carId}/images`);
//     };

//     export const createEvCarAvailability = async (
//         carId: string,
//         data: { intervals: Array<{ start_date: string; end_date: string }> }
//     ) => {
//         return client.post<ApiResponse<null>>(`/cars/ev/${carId}/availabilities`, data);
//     };
    

//     export const getEvCarAvailability = async (carId: string) => {
//         return client.get<ApiResponse<Array<{
//             id: string;
//             start_date: string;
//             end_date: string;
//         }>>>(`/cars/ev/${carId}/availabilities`);
//     };
    
    
    

//     export const deleteEvCarAvailability = async (carId: string, availabilityId: string) => {
//         return client.delete<ApiResponse<null>>(`/cars/ev/${carId}/availabilities/${availabilityId}`);
//     };



//     const evCarsApi = {
//         createEvCarProfile,
//         getEvCarProfiles,
//         updateEvCarProfile,
//         deleteEvCarProfile,
//         deleteEvCarImage,
//         uploadEvCarImage,
//         getEvCarImages,
//         getEvCarProfileById,
//         createEvCarAvailability,
//         getEvCarAvailability,
//         deleteEvCarAvailability,
//     };
      
//       export default evCarsApi;
      






import client from './index'

export interface EvCarProfile {
    place_id?: string;
    model_id?: number;
    model_make_id: string;
    model_name: string;
    model_year: number;
    model_trim?: string;
    battery_capacity: number;
    charging_connector_type: string;
        // | 'Type 1 (J1772)'
        // | 'Type 2 (Mennekes)'
        // | 'CHAdeMO'
        // | 'CCS (Combined Charging System)'
        // | 'Tesla Supercharger';
    charging_time: number;
    range_per_charge: number;
    price_per_minute_ev: number;
    price_per_km_ev: number;
    price_per_minute_up_to_100_km_ev: number;
    price_per_minute_above_100_km_ev: number;
    pause_price_ev: number;
    deposit_amount_ev: number;
    car_type: string;
    address: string;
    transmission: 'automatic';
    fuel_type: 'electric';
    no_branded_title: boolean;
    identity_country: string;
    plate_number: string;
    car_description: string;
    charging_included?: boolean;
    battery_included?: boolean;
    charging_policy?: string;
    status?: string;
    user_id: string;
    gps?: boolean;
    air_conditioner?: boolean;
    seats?: number;
    doors?: number;
    bluetooth_stereo?: boolean;
    wifi_internet?: boolean;
    fire_extinguisher?: boolean;
    ownership_permit?: string[];

    // Additional fields from validators
    electric_level?: number;
    remaining_distance?: number;
    start_price?: number;
    max_distance_per_tariff?: number;
    reservation_timer?: number;
    reservation_extension_price?: number;
    reservation_status?: string;
    pause_limit?: number;
    pause_usage_limit?: boolean;
    engine_status?: boolean;
    no_smoking?: boolean;
    current_location?: string;
    registered_car?: boolean;
    external_id?: string;
    registration_status?: string;
    battery_details?: string;
    supported_charging_types?: string[];
}

export interface ApiResponse<T> {
    message: string;
    status: string;
    code: number;
    data: T;
    meta?: {
        total: number;
        perPage: number;
        currentPage: number;
        lastPage: number;
        firstPage: string;
        firstPageUrl: string;
        lastPageUrl: string;
        nextPageUrl: string;
        previousPageUrl: string;
    };
    id?: string;
}

export interface ApiError {
    message: string;
    status: string;
    code: number;
    errors?: Record<string, unknown>;
}

export interface UploadedImage {
    file: File;
    preview: string;
}

export const createEvCarProfile = async (data: Omit<EvCarProfile, 'id'>) => {
    return client.post<ApiResponse<{ id: string }>>('/cars/ev', data);
};

export const getEvCarProfiles = async (params: Record<string, any>) => {
    return client.get<ApiResponse<EvCarProfile[]>>('/cars/ev', { params });
};

export const getEvCarProfileById = async (id: string) => {
    return client.get<ApiResponse<EvCarProfile>>(`/cars/ev/${id}`);
};

export const updateEvCarProfile = async (
    id: string,
    data: Record<string, any> | FormData
) => {
    let requestData: FormData | Record<string, any>;

    if (!(data instanceof FormData)) {
        requestData = new FormData();
        Object.entries(data).forEach(([key, value]) => {
            requestData.append(key, value as string | Blob);
        });
    } else {
        requestData = data;
    }

    console.log('🚀 Final requestData:', Array.from((requestData as FormData).entries()));

    return client.put<ApiResponse<EvCarProfile>>(`/cars/ev/${id}`, requestData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};

export const deleteEvCarProfile = async (id: string) => {
    return client.delete<ApiResponse<null>>(`/cars/ev/${id}`);
};
export const deleteEvCarImage = (carId: string, imageId: string) => {
    return client.delete<ApiResponse<null>>(`/cars/ev/${carId}/images/${imageId}`);
};

export const uploadEvCarImage = async (carId: string, file: File, type: string) => {
    const formData = new FormData();
    formData.append('image', file);
    formData.append('type', type);
    return client.post<ApiResponse<{ secure_url: string; public_id: string }>>(
        `/cars/ev/${carId}/images`,
        formData,
        {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }
    );
};

export const getEvCarImages = async (carId: string) => {
    return client.get<ApiResponse<{ secure_url: string; public_id: string }[]>>(`/cars/ev/${carId}/images`);
};

export const createEvCarAvailability = async (
    carId: string,
    data: { intervals: Array<{ start_date: string; end_date: string }> }
) => {
    return client.post<ApiResponse<null>>(`/cars/ev/${carId}/availabilities`, data);
};

export const getEvCarAvailability = async (carId: string) => {
    return client.get<ApiResponse<Array<{
        id: string;
        start_date: string;
        end_date: string;
    }>>>(`/cars/ev/${carId}/availabilities`);
};

export const deleteEvCarAvailability = async (carId: string, availabilityId: string) => {
    return client.delete<ApiResponse<null>>(`/cars/ev/${carId}/availabilities/${availabilityId}`);
};

const evCarsApi = {
    createEvCarProfile,
    getEvCarProfiles,
    updateEvCarProfile,
    deleteEvCarProfile,
    deleteEvCarImage,
    uploadEvCarImage,
    getEvCarImages,
    getEvCarProfileById,
    createEvCarAvailability,
    getEvCarAvailability,
    deleteEvCarAvailability,
};

export default evCarsApi;
